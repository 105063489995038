// Database Structure
//
// >> external_curriculums (collection)
//     > curriculum A (document ID)
//         Title: ...
//         Description: ...
//         Authors: ...
//         LastUpdated: ...
//         Link: ...

export const example_external_curriculums = [
  //   {
  //     Title: "How to Learn French: A Step-by-Step Guide for Beginners",
  //     Authors: "Miranda Parr, Preply",
  //     LastUpdated: "May 24, 2022",
  //     Link: "https://preply.com/en/blog/how-to-learn-french-step-by-step/",
  //     Subjects: ["Languages"],
  //   },
  {
    Title: "If I Started Learning Arabic Again, This Is How I'd Do It",
    Authors: "Donovan Nagel, Mezzofanti Guild",
    LastUpdated: "2022",
    Link: "https://www.mezzoguild.com/how-to-start-learning-arabic/",
    Subjects: ["Languages"],
  },
  {
    Title: "So You Want to Learn Physics…",
    Authors: "Susan Rigetti",
    LastUpdated: "2021",
    Link: "https://www.susanrigetti.com/physics",
    Subjects: ["Physics"],
  },
];
