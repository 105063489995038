// Small Textual Snippets in one file
// For Larger pieces or essays, check Public .markdown files

// Evaluating curriculums: qualitative-quantitative, trust, futurism
// Plans: quotes, futurism

export function TextCreateCurriculum() {
  // Background Information: Curriculums can only be submitted for pending. It is judged by the public in an intermediary period and by a rotating committee, before being established into the database proper.
  return (
    <>
      <h3 className="theme-h3">Now Under Construction</h3>
      <p>
        The main issue is that we don't want creating curriculums to feel like
        filling in a form. What it should be, is a fulfilling and long-term
        exercise anyone can begin doing as a means to share their journeys and
        paths, not just the results of the journey. We're working on it – crude
        estimated time of completion by December 2022.
      </p>
      <p>
        The number one priority at the moment is ensuring the sharing of
        external curriculums is as good as it can be.
      </p>
    </>
  );
}
export function TextAddCurriculum() {
  return (
    <>
      <h3 className="theme-h3">Tips for Submissions</h3>
      <p>
        Submit externally completed curriculums. For example, these can be
        websites, blogs, uploaded videos, images, diagrams. Curriculums can only
        be submitted for pending. It is judged by the public in an intermediary
        period and by a rotating committee, before being established into the
        database proper.
      </p>
    </>
  );
}
export function TextCredits() {
  return (
    <>
      <h3 className="theme-h3">Credits for Site</h3>
      <a href="https://www.flaticon.com/free-icons/info" title="info icons">
        Info icons created by Miftakhul Rizky - Flaticon
      </a>
      <a href="https://www.flaticon.com/free-icons/next" title="next icons">
        Next icons created by Roundicons - Flaticon
      </a>
    </>
  );
}

export function TextAbout() {
    return(
    <>
      <p className="centered-p">
        <p>This site is a crossroads sign. From here, I hope you'll have a clearer sense of what life has in store, and so that the maps crafted painstakingly by others can be better known and realised so you have a route to travel, with your own compass.
      </p>

<p>Iteration: Try many pathways in this one, long journey of a lifetime.</p>

<p>Itinerary: Move society in the direction of clarity that is better able to craft, discover and share learning pathways.</p>

<p>iter: journey, trip, route, course, path, road, passage; (latin) </p>

<p>Explore curriculums on this site, or more context and information in detail:</p>
      </p>
</>
  )}
